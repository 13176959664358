import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import htmlToPdf from '@/util/pdf.js'
import VueClipboard from 'vue-clipboard2';
import draggable from 'vuedraggable'
// import CKEditor from '@ckeditor/ckeditor5-vue2';
import fabric from 'fabric'
import './assets/font/font.css'
import echartsGL from 'echarts-gl'
Vue.prototype.$echartsGL = echartsGL
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import Meta from 'vue-meta'
Vue.use(Meta)
Vue.use(htmlToPdf)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueClipboard)
import 'vant/lib/index.css';
import { Icon,DatetimePicker,Popup } from 'vant';
Vue.use(DatetimePicker);
Vue.use(Icon);
Vue.use(Popup);
// Vue.use( CKEditor );
Vue.use(fabric);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
