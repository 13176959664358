<template>
    <div class="bottom-info flex_center" :style="$route.path=='/homepage/index'?'min-width: auto;':'min-width: 1110px;'">
        <div style="margin-right: 106px" class="bottom-info-div">
            @鼓楼e学校 版权所有
        </div>
        <div class="bottom-info-div">备案号：苏ICP备10228438号-6</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    mounted(){

        
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.bottom-info {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    height: 96px;
    background: #2B2C33;
    min-width: 1110px;
    /* @include center; */

    .bottom-info-div {
        font-size: 14px;
font-weight: 400;
color: #CACACA;
line-height: 20px;
    }
}
</style>
