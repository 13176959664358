import Cookies from 'js-cookie'
import * as CryptoJS from 'crypto-js'
import { Message, MessageBox } from 'element-ui';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { Dialog } from 'vant';
// import { showConfirmDialog } from 'vant';
import store from '@/store';
export const setCookieCos = (data,minutes=60) => {
    var date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    Cookies.set("learnspacecos", JSON.stringify(data), { expires: date });
}
export const getCookieCos = (key) => {
    return Cookies.get(key)
}
/**
 * 获取今天的日期
 */
export const getdate = () => {
    var myDate = new Date;
    var year = myDate.getFullYear(); //获取当前年
    var mon = myDate.getMonth() + 1; //获取当前月
    var date = myDate.getDate(); //获取当前日
    var sp='/';
    return year+sp+mon+sp+date+sp;
}
// 无token
export const noToken = () => {
  if(!getCookie('access-token')){
    MessageBox.confirm('当前未登录，请先登录', '提示', {
      confirmButtonText: '确定',
      showCancelButton:true,
      type: 'warning'
  }
  ).then(() => {
    if(isPcWindow()){
    store.commit('set_login_dialog',true)
    }else{
      window.location.href = 'https://xxkj.glzhxx.com/studyspace/#/login'
    }

  }).catch(() => {
})
  return false
  }else{
    return true
  }
}
// export const noMobileToken = () => {
//   if(!getCookie('access-token')){
//     MessageBox.confirm('当前未登录，请先登录', '提示', {
//       confirmButtonText: '确定',
//       showCancelButton:false,
//       type: 'warning'
//   }
//   ).then(() => {
//       // 去登陆
//       window.location.href = 'http://localhost:8082/#/login'

//   }).catch(() => {
   

// })
//   return false
//   }else{
//     return true
//   }
// }
//判断浏览器窗口大小
export const isPcWindow = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
              "SymbianOS", "Windows Phone","iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {//表示是移动端
          flag = false;
      }else{
          if (window.matchMedia("(max-width: 900px)").matches){//如果pc端的宽度小于750,自动进入移动端
              flag = false;
              break;
          }
      }
  }
  return flag;
}
export const getQueryVariable=(val)=>//js获取地址栏参数
{
  const w = location.hash.indexOf('?');const query = location.hash.substring(w + 1);const vars = query.split('&');for (let i = 0; i < vars.length; i++) {const pair = vars[i].split('=');if (pair[0] == val) { return pair[1]; }}return (false);
    // var location = decodeURIComponent(window.location.search);
    // var query = location.substring(1);
    // var vars = query.split("&");
    // for (var i = 0; i < vars.length; i++) {
    //     var pair = vars[i].split("=");
    //     if (pair[0] == variable) { return pair[1]; }
    // }
    // return (false);
}

/**
 * 获取随机数
 */
export const randomString = (len, charSet) => {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz,randomPoz+1);
    }
    return randomString;
}
export const setTitle = (title) => {
    document.title = title || '鼓楼e学校'
}
export const isPc = () => {//判断是pc还是移动
    // var ua = navigator.userAgent;
    // if ((ua.match(/(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i))) {
    //     if (/iPhone|iPad|iPod/.test(ua)) {
    //         return 'ios'     
    //     } else if (/Android/.test(ua)) {
    //         return 'android'   
    //     }
    // }else{
    //     if(/micromessenger/i.test(navigator.userAgent)){
    //         return 'wx';
    //     }else if(/wxwork/i.test(navigator.userAgent)){
    //         return 'wxwork';
    //     }else{
    //         return 'pc'
    //     }
    // }
    var userAgentInfo = navigator.userAgent;
                    var Agents = ["Android", "iPhone",
                                "SymbianOS", "Windows Phone",
                                "iPad", "iPod"];
                    var flag = 'pc';
                    for (var v = 0; v < Agents.length; v++) {
                        if (userAgentInfo.indexOf(Agents[v]) > 0) {
                            flag = 'mobile';
                            break;
                        }
                    }
                    console.log(flag,'flag')
                    return flag;
}
export function nopermissions(){
    MessageBox.alert("<div class='flex_column_center nodata'><img src='https://cdn.tqxxkj.cn/static/images/teacheronline/nopermissions.png' /><p>无权限查看此资源!</p></div>", {
    dangerouslyUseHTMLString: true,
    showConfirmButton:false,
    showClose:false
  });
}
// 获取cookie
export const getCookie = (name) => {
    var arr = document.cookie.match(new RegExp("(^| )"+name+"=([^;]*)(;|$)"));
    if(arr != null) {
        return unescape(arr[2]);
    }
    return null;
}
		//清空cookie
		export const clearCookie=()=> {
			var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
			if (keys) {
				for (var i = keys.length; i--;) {
					document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
				}
			}
		}
// 设置cookie
export const setCookie = (name,value) => {
    var exp  = new Date();    //new Date("December 31, 9998");
    exp.setTime(exp.getTime() + 24*60*60*1000);
    if((typeof value == "string")&&(value.length > 0)){
      document.cookie = name + "="+ escape(value) + ";expires=" + exp.toGMTString()+";path=/";
    }else{
      exp.setTime(exp.getTime() - 1);
      var cval=getCookie(name);
      if(cval!=null)
          document.cookie=name +"="+cval+";expires="+exp.toGMTString()+";path=/";
    }
}
// 删除cookie
export const removeCookie = (name) => {
    var d = new Date();
    d.setTime(d.getTime() - 1000000);
    document.cookie = name + '=1; expires=' + d.toGMTString()+";path=/";
}
export const  formateLeftTime =(lefttime) =>{
  

    var day;
    var hour;
    var min;
    var second;
  
    day = parseInt(lefttime / (24 * 60 * 60)) // 计算整数天数
    var afterDay = lefttime - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
    hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
    var afterHour = lefttime - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    min = parseInt(afterHour / 60) // 计算整数分
    second = parseInt(lefttime - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数
  
    if (day < 10) {
      day =  day;
    }
  
    if (hour < 10) {
      hour = '0' + hour
    };
  
    if (min < 10) {
      min = '0' + min;
    }
  
    if (second < 10) {
      second = '0' + second;
    }
  
  
    var restStamp = {
      day: day,
      hour: hour,
      min: min,
      second: second
    }
    return restStamp
  }
  export function formatDate(date,type){ //设置时间转换格式
    var year = date.getFullYear();  //获取年
    var month = date.getMonth() + 1;  //获取月
    month = month < 10 ? '0' + month : month;  //判断月是否大于10
    var day = date.getDate();  //获取日
    day = day < 10 ? ('0' + day) : day;  //判断日期是否大10
    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    var minute = date.getMinutes();
    minute = minute < 10 ? '0' + minute : minute;
    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if(type == 'year'){
        return year;
    }else if(type == 'month'){
      return month;
    }else if(type == 'day'){
        return day;
    }else if(type == 'line'){
      return year + '-' + month + '-' + day +' ' + hour + ":" + minute + ':' + seconds;  //返回时间格式
    }else{
        return year + '年' + month + '月' + day + '日' +' ' + hour + ":" + minute;  //返回时间格式
    }
  }
  /**
 *加密处理
 */
 export const encryption = params => {
const { data, type, param } = params
const result = JSON.parse(JSON.stringify(data))
if (type === 'Base64') {
  param.forEach(ele => {
    result[ele] = btoa(result[ele])
  })
} else {
  param.forEach(ele => {
    var data = result[ele]
    const key = CryptoJS.enc.Latin1.parse(params.key)
    var iv = key
    // 加密
    var encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding
    })
    result[ele] = encrypted.toString()
  })
}
return result
}
  export function  decodeText (text) {
    let renderDom = []
    // 文本消息
      let temp = text
  
    let left = -1
      let right = -1
      while (temp !== '' && typeof temp !== 'undefined') {
        left = temp.indexOf('[')
        right = temp.indexOf(']')
        switch (left) {
          case 0:
            if (right === -1) {
              renderDom.push({
                name: 'text',
                text: temp
              })
              temp = ''
            } else {
              let _emoji = temp.slice(0, right + 1)
              if (emojiMap[_emoji]) {
                renderDom.push({
                  name: 'img',
                  src: emojiUrl + emojiMap[_emoji]
                })
                temp = temp.substring(right + 1)
              } else {
                renderDom.push({
                  name: 'text',
                  text: '['
                })
                temp = temp.slice(1)
              }
            }
            break
          case -1:
            renderDom.push({
              name: 'text',
              text: temp
            })
            temp = ''
            break
          default:
            renderDom.push({
              name: 'text',
              text: temp.slice(0, left)
            })
            temp = temp.substring(left)
            break
        }
      }
    return renderDom
  }

export const getTreeData = (data) => {
    //递归 若children为空 将父级设置为最后一级节点
    for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
        } else {
            // children若不为空数组，则继续 递归调用 本方法
            getTreeData(data[i].children);
        }
    }
    return data;
}
export const getFileType = (type) => {
    let file_arr = ['ppt', 'pptx', 'doc', 'docx', 'xlsx', 'xls','pdf']
    let video_arr = ['mp4', 'avi', '3gp', 'mpeg', 'wmv', 'avi','mov','video']
    let img_arr = ['jpg', 'png', 'jpeg', 'svg', 'gif']
    let audio_arr = ['ogg','mp3','wav','ma4','wma','m4a']
    if(file_arr.indexOf(type) > -1){
        return 'file'
    }else if(video_arr.indexOf(type) > -1){
        return 'video'
    }else if(img_arr.indexOf(type) > -1){
        return 'img'
    }else if(audio_arr.indexOf(type) > -1){
        return 'audio'
    }else{
        return 'other'
    }
}
export const getState = (state) => {
  let state_arr = [
      {
          value:'init',
          label:'待审核'
      },
      {
          value:'pass',
          label:'已通过'
      },
      {
          value:'reject',
          label:'已驳回'
      }
  ]
  let res = state_arr.find(item => item.value == state)
  return res.label
}
export const getScopeText = (type) => {
    let arr = [
        {
            value:'my',
            label:'个人',
        },
        {
            value:'school',
            label:'校本',
        },
        {
            value:'school_group',
            label:'集团校',
        },
        {
            value:'area',
            label:'区本',
        },
    ]
    let result = arr.find(item => item.value == type)
    return result.label
}
export const getResourceType = (type) => {
    let arr = [
        {
            value:'video',
            label:'视频',
        },
        {
            value:'audio',
            label:'音频',
        },
        {
            value:'doc',
            label:'文档',
        },
        {
            value:'picture',
            label:'图片',
        },
        {
            value:'other',
            label:'其他',
        }
    ]
    let result = arr.find(item => item.value == type)
    return result.label
}
export function TreeToArray(tree) {
  // 判断 tree 是否有值，无返回 []
  if (!Array.isArray(tree) || !tree.length) return []
  let res = []
  tree.forEach(v => {
    // tree的每个元素都 放入到 res里面
    res.push(v)
    if (v.children) {
      // 有children 就把 children数据递归 返回  依次放到 res里面
      res.push(...TreeToArray(v.children))
    }
  })
  return res
}
//操作之后返回提示，pc和mobile
export function isTips(type='success',text='操作成功') {
  if(isPcWindow()){
    Message({
      showClose: true,
      message: text,
      type: type,
      duration:1000
    });		
  }else{
    Toast(text,1000);
  }
}